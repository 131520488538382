import React, { useState } from 'react'
const axios = require('axios');

function Announce() {
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState('')

  const postData = (e) => {
    const message = document.getElementById('message').value
    const password = document.getElementById('password').value
    const test = document.getElementById('test').checked

    axios.get(`https://us-central1-cg3-media.cloudfunctions.net/ShamBotAnnounce?test=${test}&announcement=${message}&password=${password}`)
    .then(() => {
      setSubmitted(true)
      setMessage('Sent successfully.')
    })
    .catch(function (error) {
      setSubmitted(true)
      setMessage('Something went wrong. Maybe the password was wrong? Tell Corey.')
      console.log(error);
    });
  
    e.preventDefault();
  }

  return <div>
    <form method="POST" onSubmit={postData}>
       { 
          submitted
          ?
          <p style={{textAlign: 'center'}}>{message}</p>
          :
          <div>
            <p>
              Send a message to your loyal and loving fans bro
            </p>

            <div className="form-group">
              <label>Message:</label>
              <textarea id="message" maxLength="159"></textarea>
            </div>

            <div className="form-group">
              <label><input type="checkbox" id="test" defaultChecked /> Send a test message? (Uncheck to send to the whole list)</label>
            </div>

            <div className="form-group">
              <label>Password:</label>
              <input type="text" id="password" />
            </div>

            <div className="form-group">
              <button type="submit">Submit</button>
            </div>
          </div>
        }
     </form>
  </div>
}

export default Announce
