import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from './pages/Home'
import Announce from './pages/Announce'

function App() {
  return <Router>
    <Switch>
      <Route path="/announce">
        <Announce />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  </Router>
}

export default App;
