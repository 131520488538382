import React, { useState, useEffect } from 'react';
import '../App.css';
const axios = require('axios');

function Home() {
  const [submitted, setSubmitted] = useState(false);

  useEffect(() =>{
    axios.get('https://v2-api.sheety.co/19076f60469ab49f49cd3be22285cd33/sms/fans')
    .then((res) => {
      let{ fans } = res.data;

        fans = fans.map((fan) => {
          return {
            name: fan.firstName,
            number: { binding_type: 'sms', address: fan.phoneNumber }
          }
        })

        console.log(fans)
      })
  })

  const postData = (e) => {
    const firstName = document.getElementById('first-name').value
    const phoneNumber = document.getElementById('number').value.match(/\d/g).join('')
  
    axios.post('https://v2-api.sheety.co/19076f60469ab49f49cd3be22285cd33/sms/fans', {
      fan: {
        firstName,
        phoneNumber,
        twitter: document.getElementById('twitter').value,
        instagram: document.getElementById('instagram').value,
        streamingService: document.getElementById('streaming-service').value
      }
    })
    .then(function (response) {
      axios.get(`https://us-central1-cg3-media.cloudfunctions.net/ShamBot?name=${firstName}&number=${phoneNumber}`)
      setSubmitted(true)
    })
    .catch(function (error) {
      console.log(error);
    });
  
    e.preventDefault();
  }

  return (
    <div className="App">
     <form method="POST" onSubmit={postData}>
       { 
          submitted
          ?
          <p style={{textAlign: 'center'}}>Preciate you mane, we’ll be in touch.</p>
          :
          <div>
            <p>
              To stay in the loop with all my shows, music, and videos, drop your number below.
              I'll shoot a text to you as everything drops.
            </p>

            <div className="form-group">
              <label>First Name:</label>
              <input type="text" id="first-name" name="first_name" placeholder="Shamika, Kiesha, Tara, etc." required />
            </div>

            <div className="form-group">
              <label>Mobile phone number:</label>
            <input type="tel" id="number" name="number" placeholder="e.g. 323-555-5555" required />
            </div>

            <div className="form-group">
              <label>Twitter (optional):</label>
            <input type="twitter" id="twitter" pattern="[@A-Za-z0-9._]+" />
            </div>

            <div className="form-group">
              <label>Instagram (optional):</label>
            <input type="instagram" id="instagram" pattern="[@A-Za-z0-9._]+" />
            </div>
            
            <div className="form-group">
            <label>Streaming service (Spotify, Apple Music, etc):</label>
              <select name="streaming-service" id="streaming-service" required>
                <option>Select One</option>
                <option value="spotify">Spotify</option>
                <option value="apple-music">Apple Music</option>
                <option value="tidal">Tidal</option>
                <option value="google-play">Google Play</option>
                <option value="amazon">Amazon Music</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="form-group">
              <button type="submit">Submit</button>
            </div>
          </div>
        }
     </form>
    </div>
  );
}

export default Home;
